import React, { useContext } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import Chevron from 'img/chevron.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'
import { PostContext } from 'templates/post'
import { LatestBlogProps } from 'components/flex/Posts/Latest/LatestShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 1rem;
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }

  & a {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & tbody, td, tfoot, th, thead, tr {
    border: 1px solid ${({ theme }) => theme.color.primary};
  }
`

const CtaContent = styled(ParseContent)`

  & a {
    font-size: 14px;
  }
`

const Cta = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
`

const Image = styled(Plaatjie)`
  margin-top: -5.5rem;
  border: 7px solid ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  width: 134px;
  height: 134px;
`

const RecentPostsWrapper = styled.div`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }
`

const TextRecentPosts: React.FC<TextProps> = ({ fields }) => {
  const { cta } = fields

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row justify-content-between">
              <div className="col-md-8 col-xl-9 pe-lg-5">
                <Content content={fields.description} />
              </div>
              <div className="col-md-4 col-xl-3 pt-5 pt-lg-0">
                <Cta className="d-flex flex-column align-items-center p-4 mb-5 mt-5">
                  <Image image={cta?.image} alt="" />
                  <CtaContent content={cta?.description} className="mt-4" />
                </Cta>
              </div>
            </div>
            <RecentPostsWrapper className="pt-3 pt-lg-5">
              {/* @ts-ignore */}
              <RecentPosts fields={fields} />
            </RecentPostsWrapper>
          </div>
        </div>
      </div>
    </section>
  )
}

const RecentPosts: React.FC<LatestBlogProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.latestPostsQueryQuery>(graphql`
    query latestPostsQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <div>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="latest-blog"
        limit={3}
      >
        <h2 className="mb-3 mb-lg-5 pt-lg-5">Meer wereldnieuws</h2>
        <BlogGrid fields={fields} />
      </BlogBink>
    </div>
  )
}

const BlogGrid: React.FC<LatestBlogProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  const context = useContext(PostContext)

  return (
    <div className="row pt-3">
      {blogBink.posts
        // @ts-ignore
        .filter((post) => post.node.databaseId !== context.databaseId)
        .map((post) => {
          const { node }: any = post

          return (
            <React.Fragment key={post.node.id}>
              <BlogGridPost node={node} fields={fields} />
            </React.Fragment>
          )
        })}
    </div>
  )
}

const Category = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  z-index: 2;
  top: 25px;
  left: 30px;
  & span {
    color: ${({ theme }) => theme.color.light};
  }
`

const Recap = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & h2 {
    font-size: 22px;
    font-weight: ${({ theme }) => theme.font.weight.bold};

    @media (min-width: 992px){
      height: 80px;
    }
  }

  & svg {
    width: 14px;
    height: 21px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  display: block !important;

  & img {
    height: 250px !important;
    width: 100% !important;

    object-fit: cover !important;
  }
`

const BlogGridPost: React.FC<LatestBlogProps> = ({ node }) => {
  const category = node.categories.nodes[0].name

  return (
    <div className="col-lg-4 position-relative mb-5">
      <NavLink to={node.uri}>
        <Category className="position-absolute px-5 py-2">
          <span>{category}</span>
        </Category>
        <StyledPlaatjie image={node.recap.thumbnail} alt="" />
        <Recap className="p-4">
          <h2>{node.title}</h2>
          <div className="d-flex justify-content-end">
            <Chevron />
          </div>
        </Recap>
      </NavLink>
    </div>
  )
}

export default TextRecentPosts
