import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }

  & a {
    color: ${({ theme }) => theme.color.dark};
    font-weight: bold;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`

const Text: React.FC<TextProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            <div className={`col-lg-${fields.colwidth}`}>
              <Content content={fields.description} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Text
