import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const Cta = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
`

const Image = styled(Plaatjie)`
  border: 5px solid ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.primary};

  // Enables border radius in Safari etc.
  & img {
    border-radius: 50%;
  }

  @media (min-width: 992px) {
    width: 184px;
    height: 184px;
    margin-top: -8.5rem;
  }

  @media (max-width: 991px) {
    width: 134px;
    height: 134px;
    margin-top: -5.5rem;
  }
`

const PersonInfoWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & h3 {
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: 800;
  }

  & a {
    text-align: center;
    color: white;
  }
`

const TextCta: React.FC<TextProps> = ({ fields }) => {
  const { cta } = fields

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row justify-content-between align-items-start">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <Content content={fields.description} />
              </div>
              <div className="col-lg-4">
                <Cta className="d-flex flex-column align-items-center p-4 mt-5">
                  <Image image={cta?.image} alt="" />
                  <PersonInfoWrapper>
                    <h3 className="text-white text-center mt-3 mb-0">
                      {cta?.name}
                    </h3>
                    <a target="_blank" rel="noreferrer" href={cta?.phone?.url}>
                      {cta?.phone?.title}
                    </a>
                    <a target="_blank" rel="noreferrer" href={cta?.mail?.url}>
                      {cta?.mail?.title}
                    </a>
                  </PersonInfoWrapper>
                  <ParseContent content={cta?.description} className="mt-4" />
                </Cta>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextCta
