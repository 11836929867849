import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Images
import Quote from 'img/quote.inline.svg'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const QuoteWrapper = styled.div`
  & h2 {
    opacity: 0.1;

    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    @media (min-width: 1199px) {
      font-size: 157px;
      margin-left: -7.5rem;
      margin-bottom: -4.5rem;
      margin-top: 7rem;
    }
    @media (max-width: 1200px) {
      font-size: 90px;
      margin-bottom: -2.5rem;
    }
  }

  & svg {
    position: relative;
    z-index: 3;
    margin-bottom: -2.25rem;
    margin-left: 1.75rem;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 1199px) {
    width: 660px;
    margin-left: -6.5rem;
  }
`

const TextImage: React.FC<TextProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container py-lg-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            {fields.isquote ? (
              <WithQuote fields={fields} />
            ) : (
              <Default fields={fields} />
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
)

const DefaultContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const Default: React.FC<TextProps> = ({ fields }) => (
  <>
    <div className="col-lg-6">
      <DefaultContent content={fields.description} />
    </div>
    <div className="col-lg-6">
      <Plaatjie image={fields.image} alt="" />
    </div>
  </>
)

const QuoteContent = styled(ParseContent)`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.primary};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }

  & blockquote p {
    opacity: 1;
    padding-bottom: 1.2rem;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
    @media (min-width: 992px) {
      font-size: 36px;
    }
    @media (max-width: 991px) {
      font-size: 26px;
    }
  }

  & p {
    font-size: 18px;
    opacity: 0.8;
  }
`

const WithQuote: React.FC<TextProps> = ({ fields }) => (
  <>
    <div className="col-xl-6">
      <QuoteWrapper>
        <h2>QUOTE</h2>
        <Quote />
      </QuoteWrapper>

      <QuoteContent content={fields.description} className="p-lg-5 p-4" />
    </div>
    <div className="col-xl-6">
      <Image image={fields.image} alt="" />
    </div>
  </>
)

export default TextImage
