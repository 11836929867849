import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import FormDuuf from 'components/related/Form'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  background: ${({ theme }) => theme.color.light};

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 10px 20px;
    background-color: #f2f2f2;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
`

const TextForm: React.FC<TextProps> = ({ fields }) => {
  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row justify-content-between align-items-start">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <Content content={fields.description} />
              </div>
              <div className="col-lg-4">
                <FormWrapper className="p-lg-5 p-4">
                  <Content content={fields.formtitle} className="mb-5" />
                  <StyledFormDuuf id={fields.form || 3} generate />
                </FormWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextForm
