import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'
import { motion } from 'framer-motion'
import useMedia from 'use-media'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }

  & a {
    color: ${({ theme }) => theme.color.dark};
    font-weight: bold;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`

const Usp = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & span {
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 50px !important;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  @media (min-width: 767px) {
    font-size: 30px;
  }
`

const TriangleCSS = css`
  top: 0;
  width: 0;
  height: 0;

  @media (min-width: 768px) {
    border-top: 34px solid transparent;
    border-bottom: 34px solid transparent;
  }
  @media (max-width: 767px) {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
`

const TriangleBackground = styled.div`
  ${TriangleCSS}
  left: 0;
  @media (min-width: 768px) {
    border-left: 34px solid ${({ theme }) => theme.color.grey};
  }
  @media (max-width: 767px) {
    border-left: 20px solid ${({ theme }) => theme.color.grey};
  }
`

const TrianglePrimary = styled.div`
  ${TriangleCSS}
  @media (min-width: 768px) {
    border-left: 34px solid ${({ theme }) => theme.color.primary};
    right: -34px;
  }
  @media (max-width: 767px) {
    border-left: 20px solid ${({ theme }) => theme.color.primary};
    right: -20px;
  }
`

const UspChevron = styled.div`
  top: 9px;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.secondary};
  content: '';
  display: inline-block;
  transform: rotate(45deg);
  vertical-align: top;
  left: -2.25rem;
  z-index: 2;
  @media (min-width: 768px) {
    width: 49px;
    height: 49px;
    border-width: 13px 13px 0 0;
  }
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
    left: -1.35rem;
    border-width: 8px 8px 0 0;
    top: 5px;
  }
`

const TextMaintenance: React.FC<TextProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <Content content={fields?.description} />
        </div>
        <div className="col-lg-5 d-flex justify-content-end flex-column">
          {fields?.item?.map((e, i) => (
            <Usp className="position-relative text-center mb-4 px-4 py-2">
              <motion.div
              >
                <UspChevron className="position-absolute" />
              </motion.div>
              <TriangleBackground className="position-absolute" />
              <span>{e?.title}</span>
            </Usp>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default TextMaintenance
